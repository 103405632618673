<template>
  <el-form ref="form" :model="formData" label-width="120px">
    <el-form-item label="站点名称">
      <el-input style="width: 400px" v-model="formData.config_data.company_name" size="small"
                placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="logo">
      <UploaderImg :url.sync="formData.config_data.logo"></UploaderImg>
    </el-form-item>
    <el-form-item label="网站关键词">
      <el-input style="width: 400px" type="textarea" v-model="formData.config_data.seo_keywords" size="small"
                placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="网站介绍">
      <el-input style="width: 400px" type="textarea" v-model="formData.config_data.seo_description" size="small"
                placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="工作时间">
      <el-input style="width: 400px" v-model="formData.config_data.business_hours" size="small"
                placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="官方电话">
      <el-input style="width: 400px" v-model="formData.config_data.official_phone" size="small"
                placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="官方邮箱">
      <el-input style="width: 400px" v-model="formData.config_data.official_email" size="small"
                placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="公司地址">
      <el-input style="width: 400px" type="textarea" v-model="formData.config_data.headquarters_address" size="small"
                placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="">
      <el-button type="" size="small">取 消</el-button>
      <el-button type="primary" size="small" @click="save">保 存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {getSettingConfigApi, settingConfigApi} from "./api";
import UploaderImg from '@/components/qiniu-uploader/uploader-img.vue'

export default {
  name: 'Site',
  components: {UploaderImg},
  data() {
    return {
      formData: {
        key: 'company_info',
        describe: '站点信息',
        config_data: {
          company_name: '',
          logo: '',
          seo_keywords: '',
          seo_description: '',
          business_hours: '',
          official_email: '',
          headquarters_address: '',
          official_phone: '',
          biz_cooperation_phone: '',
        }
      },
    }
  },

  mounted() {
    this.getDetail()
  },

  methods: {
    save() {
      settingConfigApi(this.formData).then((res) => {
        this.getDetail()
        this.$message.success('更新成功')
      })
    },
    getDetail() {
      getSettingConfigApi(this.formData.key).then(res => {
        this.formData.config_data = res.config_data
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
