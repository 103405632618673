<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="activeName">
      <el-tab-pane label="站点设置" name="site"></el-tab-pane>
      <el-tab-pane label="优势" name="advantage"></el-tab-pane>
      <el-tab-pane label="关注我们" name="followus"></el-tab-pane>
    </el-tabs>
    <Site v-if="activeName === 'site'"></Site>
    <Storage v-if="activeName === 'storage'"></Storage>
    <Advantage v-if="activeName === 'advantage'"></Advantage>
    <follow-us v-if="activeName === 'followus'"></follow-us>
  </div>
</template>

<script>
import Storage from './modules/storage.vue'
import Site from './modules/site.vue'
import Advantage from './modules/advantage.vue'
import FollowUs from './modules/followUs.vue'

export default {
  name: 'List',
  components: {Storage, Site, Advantage, FollowUs},
  data() {
    return {
      activeName: 'site'
    }
  },

  mounted() {
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
